.audio-call-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #121212;
  }
  
  .audio-indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
  }
  
  .audio-circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #ddd;
    margin-bottom: 10px;
    transition: transform 0.3s ease-in-out;
  }
  
  .talking {
    background-color: #00E0FF;
    transform: scale(1.3); /* Enlarge the circle when talking */
  }
  
  button {
    padding: 10px 20px;
    background-color: #FFFFFF20;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  button:hover {
    background-color: #FFFFFF20;
  }
  