/* Container for the entire video call interface */
.video-call-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #121212;
    padding: 20px;
    box-sizing: border-box;
    
  }
  
  /* Header Styling */
  .video-call-container h1 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* Wrapper to position videos side-by-side */
  .videos-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    max-width: 800px;
    margin-bottom: 20px;
  }
  
  /* Box around each video element */
  .video-box {
    position: relative;
    width: 400px;
    height: 350px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #77767601;
    margin: 20px;
  }
  
  /* Styling for the video element */
  .video-element {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  
  /* Video labels (like 'You' or 'Remote User') */
  .video-label {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
  }
  
  /* End call button */
  .end-call-button {
    padding: 12px 25px;
    background-color: #FFFFFF20;
    color: #fff;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    transition: background-color 0.3s ease;
  }
  
  .end-call-button:hover {
    background-color: #FFFFFF20;
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .videos-wrapper {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-height: 100%;
    }
  
    .video-box {
      width: 100%;
      max-width: 350px;
      max-height: 300px;
      margin-bottom: 20px;
    }
  }
  